import { Action } from 'redux'
import {
  PdfViewerState,
  PronunciationPublishResponse,
  PronunciationResponse,
  PronunciationsAddItemActionPayload,
  PronunciationsDeleteItemActionPayload,
  PronunciationsEditItemActionPayload,
  PronunciationsFetchActionPayload,
  PronunciationsPublishActionPayload,
  PronunciationsSelectItemsActionPayload,
  PronunciationsSetCredentialsActionPayload,
  PronunciationsSetTxtContentActionPayload,
  PronunciationsUpdateActionPayload,
  PronunciationUpdateActionResponse,
  TTSReaderOptions,
  TTSResponse,
  PronunciationItem,
} from '../../../typings'

/**
 * Fetch
 */
export const PRONUNCIATIONS_FETCH = 'PRONUNCIATIONS_FETCH'
export const PRONUNCIATIONS_FETCH_SUCCESS = 'PRONUNCIATIONS_FETCH_SUCCESS'
export const PRONUNCIATIONS_RESET_GUIDE_SET = 'PRONUNCIATIONS_RESET_GUIDE_SET'
export const PRONUNCIATIONS_FETCH_FAILURE = 'PRONUNCIATIONS_FETCH_FAILURE'

/**
 * Update
 */
export const PRONUNCIATIONS_UPDATE = 'PRONUNCIATIONS_UPDATE'
export const PRONUNCIATIONS_UPDATE_SUCCESS = 'PRONUNCIATIONS_UPDATE_SUCCESS'
export const PRONUNCIATIONS_UPDATE_FAILURE = 'PRONUNCIATIONS_UPDATE_FAILURE'

/**
 * Delete
 */
export const PRONUNCIATIONS_DELETE = 'PRONUNCIATIONS_DELETE'
export const PRONUNCIATIONS_DELETE_SUCCESS = 'PRONUNCIATIONS_DELETE_SUCCESS'
export const PRONUNCIATIONS_DELETE_FAILURE = 'PRONUNCIATIONS_DELETE_FAILURE'

/**
 * PUBLISH
 */
export const PRONUNCIATIONS_PUBLISH = 'PRONUNCIATIONS_PUBLISH'
export const PRONUNCIATIONS_PUBLISH_SUCCESS = 'PRONUNCIATIONS_PUBLISH_SUCCESS'
export const PRONUNCIATIONS_PUBLISH_FAILURE = 'PRONUNCIATIONS_PUBLISH_FAILURE'

/**
 * ITEMS
 */
export const PRONUNCIATIONS_ADD_ITEM = 'PRONUNCIATIONS_ADD_ITEM'
export const PRONUNCIATIONS_DELETE_ITEM = 'PRONUNCIATIONS_DELETE_ITEM'
export const PRONUNCIATIONS_EDIT_ITEM = 'PRONUNCIATIONS_EDIT_ITEM'
export const PRONUNCIATIONS_SELECT_ITEMS = 'PRONUNCIATIONS_SELECT_ITEMS'
export const PRONUNCIATIONS_DELETE_SELECTED_ITEMS = 'PRONUNCIATIONS_DELETE_SELECTED_ITEMS'

/**
 * TEXT TO SPEECH
 */
export const PRONUNCIATIONS_FETCH_TTS = 'PRONUNCIATIONS_FETCH_TTS'
export const PRONUNCIATIONS_FETCH_TTS_SUCCESS = 'PRONUNCIATIONS_FETCH_TTS_SUCCESS'
export const PRONUNCIATIONS_FETCH_TTS_FAILURE = 'PRONUNCIATIONS_FETCH_TTS_FAILURE'

/**
 * Local state only
 */
export const PRONUNCIATIONS_SET_STALE = 'PRONUNCIATIONS_SET_STALE'
export const PRONUNCIATIONS_SAVE = 'PRONUNCIATIONS_SAVE'
export const PRONUNCIATIONS_BLUR_COMMENT_FIELD = 'PRONUNCIATIONS_BLUR_COMMENT_FIELD'
export const PRONUNCIATIONS_SET_TXT_CONTENT = 'PRONUNCIATIONS_SET_TXT_CONTENT'
export const PRONUNCIATIONS_SET_VIEWER_STATE = 'PRONUNCIATIONS_SET_VIEWER_STATE'
export const PRONUNCIATIONS_SET_CREDENTIALS = 'PRONUNCIATIONS_SET_CREDENTIALS'
export const PRONUNCIATIONS_UPDATE_TTS_READER_OPTIONS = 'PRONUNCIATIONS_UPDATE_TTS_READER_OPTIONS'
export const PRONUNCIATIONS_RESET_COMMON_INFO = 'PRONUNCIATIONS_RESET_COMMON_INFO'
export const PRONUNCIATIONS_SET_ITEMS = 'PRONUNCIATIONS_SET_ITEMS'
export const PRONUNCIATIONS_SET_FOCUS = 'PRONUNCIATIONS_SET_FOCUS'
export const PRONUNCIATIONS_SET_COMMON_INFO = 'PRONUNCIATIONS_SET_COMMON_INFO'

/**
 * GuideSet copy
 */
export const PRONUNCIATIONS_SET_COPY = 'PRONUNCIATIONS_SET_COPY'

/**
 * Actions - FETCH
 */

export interface PronunciationsFetchAction extends PronunciationsFetchActionPayload, Action {
  type: 'PRONUNCIATIONS_FETCH'
}

export const fetchPronunciations = (payload: PronunciationsFetchActionPayload): PronunciationsFetchAction => {
  return {
    type: PRONUNCIATIONS_FETCH,
    ...payload,
  }
}

export interface PronunciationsFetchSuccessAction extends Action {
  type: 'PRONUNCIATIONS_FETCH_SUCCESS'
  payload: PronunciationResponse
}

/**
 * Actions - SET COPY OF GUIDESET
 * Set copy of guide set, so we can later use this when undoing changes after save
 */

export interface PronunciationsSetGuideSetCopy extends Action {
  type: 'PRONUNCIATIONS_SET_COPY'
  payload: PronunciationResponse
}

/**
 * Actions - UPDATE
 */

export interface PronunciationsUpdateAction extends PronunciationsUpdateActionPayload, Action {
  type: 'PRONUNCIATIONS_UPDATE'
}

export const updatePronunciations = (params: PronunciationsUpdateActionPayload): PronunciationsUpdateAction => {
  return {
    type: PRONUNCIATIONS_UPDATE,
    organizationId: params.organizationId,
    isbn: params.isbn,
    lastUpdated: params.lastUpdated,
    payload: params.payload,
  }
}
export interface PronunciationsUpdateSuccessAction extends Action {
  type: 'PRONUNCIATIONS_UPDATE_SUCCESS'
  payload: { guideSet: PronunciationUpdateActionResponse; isbn: string }
}

export interface PronunciationsUpdateFailureAction extends Action {
  type: 'PRONUNCIATIONS_UPDATE_FAILURE'
}

// ADD ITEM
export interface PronunciationsAddItemAction extends PronunciationsAddItemActionPayload, Action {
  type: 'PRONUNCIATIONS_ADD_ITEM'
}

export const addPronunciationItem = (params: PronunciationsAddItemActionPayload): PronunciationsAddItemAction => {
  return {
    type: PRONUNCIATIONS_ADD_ITEM,
    ...params,
  }
}

// EDIT ITEM
export interface PronunciationsEditItemAction extends PronunciationsEditItemActionPayload, Action {
  type: 'PRONUNCIATIONS_EDIT_ITEM'
}

export const editPronunciationItem = (params: PronunciationsEditItemActionPayload): PronunciationsEditItemAction => {
  return {
    type: PRONUNCIATIONS_EDIT_ITEM,
    ...params,
  }
}

// DELETE ITEM
export interface PronunciationsDeleteItemAction extends PronunciationsDeleteItemActionPayload, Action {
  type: 'PRONUNCIATIONS_DELETE_ITEM'
}

export const deletePronunciationItem = (
  params: PronunciationsDeleteItemActionPayload
): PronunciationsDeleteItemAction => {
  return {
    type: PRONUNCIATIONS_DELETE_ITEM,
    ...params,
  }
}

// SELECT ITEMS BY ID
export interface PronunciationsSelectItemsAction extends PronunciationsSelectItemsActionPayload, Action {
  type: 'PRONUNCIATIONS_SELECT_ITEMS'
}

export const selectPronunciationItems = (
  params: PronunciationsSelectItemsActionPayload
): PronunciationsSelectItemsAction => {
  return {
    type: PRONUNCIATIONS_SELECT_ITEMS,
    ...params,
  }
}

// DELETE SELECTED ITEMS
export interface PronunciationsDeleteSelectedItemsAction extends Action {
  type: 'PRONUNCIATIONS_DELETE_SELECTED_ITEMS'
}

export const deleteSelectedPronunciationItems = (): PronunciationsDeleteSelectedItemsAction => {
  return {
    type: PRONUNCIATIONS_DELETE_SELECTED_ITEMS,
  }
}

// PUBLISH
export type PronunciationsPublishAction = {
  type: 'PRONUNCIATIONS_PUBLISH'
} & PronunciationsPublishActionPayload

export const publishPronunciations = (params: PronunciationsPublishActionPayload): PronunciationsPublishAction => {
  return {
    type: PRONUNCIATIONS_PUBLISH,
    ...params,
  }
}

export type PronunciationsPublishSuccessAction = {
  type: 'PRONUNCIATIONS_PUBLISH_SUCCESS'
  payload: PronunciationPublishResponse
}

// TTS

// Fetch TTS settings
export type PronunciationsFetchTextToSpeechAction = {
  type: 'PRONUNCIATIONS_FETCH_TTS'
}

export const fetchTextToSpeechSettings = (): PronunciationsFetchTextToSpeechAction => {
  return {
    type: PRONUNCIATIONS_FETCH_TTS,
  }
}

export interface PronunciationsFetchTextToSpeechSuccessAction extends Action {
  type: 'PRONUNCIATIONS_FETCH_TTS_SUCCESS'
  payload: TTSResponse
}

/**
 * LOCAL ACTIONS
 */

export type PronunciationsSetCredentialsAction = {
  type: 'PRONUNCIATIONS_SET_CREDENTIALS'
  payload: PronunciationsSetCredentialsActionPayload
}

export const setPronunciationsCredentials = (
  params: PronunciationsSetCredentialsActionPayload
): PronunciationsSetCredentialsAction => {
  return {
    type: PRONUNCIATIONS_SET_CREDENTIALS,
    payload: params,
  }
}
export interface PronunciationsSetStaleAction extends Action {
  type: 'PRONUNCIATIONS_SET_STALE'
  payload: {
    isStale: boolean
  }
}

export const setPronunciationsStale = (isStale: boolean): PronunciationsSetStaleAction => {
  return {
    type: PRONUNCIATIONS_SET_STALE,
    payload: { isStale },
  }
}

export interface PronunciationsSetSaving extends Action {
  type: 'PRONUNCIATIONS_SAVE'
  payload: {
    shouldSave: boolean
  }
}

export const setSaving = (shouldSave: boolean): PronunciationsSetSaving => {
  return {
    type: PRONUNCIATIONS_SAVE,
    payload: { shouldSave },
  }
}

export interface PronunciationsUpdateTextToSpeechReaderOptionsAction extends Action {
  type: 'PRONUNCIATIONS_UPDATE_TTS_READER_OPTIONS'
  payload: Partial<TTSReaderOptions>
}

export const updateTtsReaderOptions = (
  payload: Partial<TTSReaderOptions>
): PronunciationsUpdateTextToSpeechReaderOptionsAction => {
  return {
    type: PRONUNCIATIONS_UPDATE_TTS_READER_OPTIONS,
    payload,
  }
}

export type PronunciationsSetTxtContentAction = {
  type: 'PRONUNCIATIONS_SET_TXT_CONTENT'
} & PronunciationsSetTxtContentActionPayload

export const setPronunciationsTextContent = (
  params: PronunciationsSetTxtContentActionPayload
): PronunciationsSetTxtContentAction => {
  return {
    type: PRONUNCIATIONS_SET_TXT_CONTENT,
    ...params,
  }
}

export type PronunciationsSetViewerStateAction = {
  type: 'PRONUNCIATIONS_SET_VIEWER_STATE'
  payload: Partial<PdfViewerState>
}

export const setPdfViewerState = (params: Partial<PdfViewerState>): PronunciationsSetViewerStateAction => {
  return {
    type: PRONUNCIATIONS_SET_VIEWER_STATE,
    payload: params,
  }
}

export type PronunciationsResetGuideSet = {
  type: 'PRONUNCIATIONS_RESET_GUIDE_SET'
}

export const resetGuideSet = (): PronunciationsResetGuideSet => {
  return {
    type: PRONUNCIATIONS_RESET_GUIDE_SET,
  }
}

/**
 * Action - Undo common info change
 */
export interface PronunciationsResetCommonInfo {
  type: 'PRONUNCIATIONS_RESET_COMMON_INFO'
}

export const resetCommonInfo = (): PronunciationsResetCommonInfo => ({
  type: PRONUNCIATIONS_RESET_COMMON_INFO,
})

/**
 * Replace draft items
 */

export interface PronunciationsSetOldSnapshot extends Action {
  type: 'PRONUNCIATIONS_SET_ITEMS'
  items: PronunciationItem[]
}

export const setGuideItems = (items: PronunciationItem[]) => ({
  type: PRONUNCIATIONS_SET_ITEMS,
  items,
})

/**
 * Set blur
 */
export interface PronunciationsSetBlur extends Action {
  type: 'PRONUNCIATIONS_SET_FOCUS'
  isFocused: boolean
}

export const setFocus = (isFocused: boolean) => ({
  type: PRONUNCIATIONS_SET_FOCUS,
  isFocused,
})

/**
 * Set update common info values
 */

export interface PronunciationsSetCommonInfoPayload {
  bookDetails: string
  generalInstructions: string
}

export interface PronunciationsCommonInfoAction extends Action, PronunciationsSetCommonInfoPayload {
  type: 'PRONUNCIATIONS_SET_COMMON_INFO'
}

export const setCommonInfo = ({ ...payload }: PronunciationsSetCommonInfoPayload) => ({
  type: PRONUNCIATIONS_SET_COMMON_INFO,
  ...payload,
})
